.base-container {
  background-color: white;
  color: white;
  border-radius: 10px;
  padding: 0.5rem 0 0;
  box-shadow: var(--box-shadow);
  margin-bottom: 1rem;
}

.scroll {
  background-color: white;
  height: fit-content;
  max-height: 47vh;
  overflow-x: scroll;
  overflow-y: auto;
  text-align: center;
  border-radius: 10px;
}

table {
  margin-bottom: 0 !important;
}

table thead {
  border-radius: 10px;
  padding: 0.5rem 0.5rem !important;
}

table th {
  position: sticky !important;
  top: 0px !important;
  background-color: white !important;
  z-index: 2 !important;
}

thead.table-title,
td {
  border-style: hidden !important;
  vertical-align: middle;
}

.no-contact {
  background-color: #fcdada;
}

td.confirmed select,
td.cancelled select,
td.call select,
td.other select,
td.no-reply select,
td.rescheduled select,
td.no-answer select,
td.patient-will-call select {
  min-width: 150px !important;
}

td.confirmed,
td.confirmed select,
td.confirmed select:focus {
  color: rgb(34, 165, 34);
}

td.cancelled,
td.cancelled select,
td.cancelled select:focus {
  color: rgba(255, 0, 0, 0.803);
}

td.call,
td.call select,
td.call select:focus {
  color: rgb(0, 130, 181);
}

td.other,
td.other select,
td.other select:focus {
  color: #5D3891;
}

td.no-reply,
td.no-reply select,
td.no-reply select:focus {
  color: #F99417;
}

td.not-attended,
td.not-attended select,
td.not-attended select:focus {
  color: #f917b1;
}

td.rescheduled,
td.rescheduled select,
td.rescheduled select:focus {
  color: #175dce;
}

td.no-answer,
td.no-answer select,
td.no-answer select:focus {
  color: #f94f17;
}

td.patient-will-call,
td.patient-will-call select,
td.patient-will-call select:focus {
  color: #ff55e8;
}

.table .main-btn:hover {
  font-weight: 400;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 10px;
}

.header-content:hover {
  background-color: #f1f1f1;
}

.fa-filter {
  margin-left: 5px !important;
}

.fa-filter.active {
  color: var(--color-primary-orange);
}

.active-filter {
  color: var(--color-primary-orange);
  border-radius: 10px;
}

@media (max-width: 1028px) {
  .scroll {
    max-height: 300px !important;
  }

  table {
    font-size: 0.8rem !important;
  }

  td.confirmed select,
  td.cancelled select,
  td.call select,
  td.other select,
  td.no-reply select,
  td.not-attended select,
  td.rescheduled select {
    min-width: 110px !important;
    font-size: 0.8rem !important;
  }
}