.usage-card {
  background-color: white;
  padding: 7px 15px;
  border-radius: 10px;
  box-shadow: var(--box-shadow);
  margin: 10px 0;
  text-align: start;
}

.usage-card h3 {
  margin-bottom: 0 !important;
  font-weight: 600;
  font-size: 1.1rem;
}

.usage-card h5 {
  margin: 0 !important;
  font-size: 0.8rem;
}

.usage-card-row {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}

.usage-card-row .col-7,
.usage-card-row .col-4,
.usage-card-row .col-3 {
  padding: 5px 0;
}

.usage-card-row p {
  text-align: end !important;
  font-size: 0.8rem;
}

.usage-card-row .col-3 p {
  text-align: end !important;
}

.dark-txt {
  color: var(--color-dark-text) !important;
}

.total-card {
  background-color: #F2F2FD;
  color: #0808B2;
}

.confirm-card {
  background-color: #EAFAF5;
  color: #2AE9D3;
}

.cancel-card {
  background-color: #FDEDEC;
  color: #FF756B;
}

.call-card {
  background-color: #F0FAFE;
  color: #6bdfff;
}

.payment-card {
  background-color: #d8e2ff;
  color: #5678dc;
}

.other-card {
  background-color: #fdf0db;
  color: #ffb13c;
}

.ignored-card {
  background-color: #ffe3ec;
  color: #fd2f6d;
}

.not-attended-card {
  background-color: #f0f0f0;
  color: #a0a0a0;
}

.rescheduled-card {
  background-color: #dae8ff;
  color: #175dce;
}

.no-answer-card {
  background-color: #ffdfdf;
  color: #ff0000;
}

.patient-will-call-card {
  background-color: #ffe1fb;
  color: #ff55e8;
}

.loaderDiv {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-left: 5px;
}

.loaderDiv span {
  margin-left: 5px;
}

.card-footer-stats {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 2px;
  width: 100%;
}

.percentage-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fa-arrow-up {
  color: #10b981 !important;
}

.fa-arrow-down {
  color: #FF756B !important;
}

.trend-icon-stats {
  font-size: 0.8rem !important;
  margin-right: 5px !important;
}

.trend-text-stats {
  font-size: 0.8rem;
  font-weight: 500;
}

.increasing-stats {
  color: #10b981;
}

.decreasing-stats {
  color: #ef4444;
}

.name-content {
  display: flex;
  justify-content: space-around !important;
  align-items: center;
  padding: 6px 4px !important;
}

.no-percentage {
  font-size: 0.6rem !important;
  color: var(--color-dark-text);
  padding: 2.5px 0 !important;
}

@media (max-width: 768px) {
  .usage-card {
    padding: 7px 10px;
  }

  .usage-card h3 {
    font-size: 1rem;
  }

  .usage-card h5 {
    font-size: 0.7rem;
  }

  .usage-card-row p {
    font-size: 0.7rem;
  }

  .usage-card-row .col-3 p {
    font-size: 0.7rem;
  }

  .usage-card-row .col-7,
  .usage-card-row .col-4,
  .usage-card-row .col-3 {
    padding: 0;
  }

  .name-content {
    padding: 6px 2px !important;
  }

  .card-footer-stats {
    display: flex;
    justify-content: center;
  }
}