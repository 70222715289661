.users-container .scroll {
  max-height: 550px;
}

.users-table-options {
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-btn {
  margin-right: 3px !important;
}

@media (max-width: 1028px) {
  .users-container td select {
    font-size: 0.8rem !important;
  }

  td button.main-btn {
    font-size: 0.8rem !important;
  }
}